import request from '@/utils/request';

//接口：配置打包费(查询)
//地址：http://${BASE_URL}/order-service/orderOSM/distribution
export function distributionPOST() {
    return request({
        url: '/order-service/orderOSM/distribution',
        method: 'post', 
    })
}


//接口：配置打包费(修改)
//地址：http://${BASE_URL}/order-service/orderOSM/distribution
export function distributionPUT(data) {
    return request({
        url: `/order-service/orderOSM/distribution`,
        method: 'put',
        data
    })
}