<template>
  <div>
    <el-row>
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <el-form :model="form" :rules="rules" ref="form">
            <el-form-item label="每单打包费为" prop="packingFee">
              <el-input
                placeholder="请输入内容"
                v-model="form.packingFee"
                class="form-input-400"
                @keyup.native="form.packingFee = onInput(form.packingFee)"
              >
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="自提周期" prop="invalidCycle">
              <el-input
                placeholder="请输入内容"
                v-model="form.invalidCycle"
                class="form-input-400"
              >
                <template slot="append">天</template>
              </el-input>
            </el-form-item>
            <el-form-item label="配送方式">
              <el-radio-group v-model="form.deliveryOptions">
                <el-radio :label="0">仅配送到家</el-radio>
                <el-radio :label="1">自提+配送</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-button
                    class="form-button ml-15"
                    type="primary"
                    @click="submit('form')"
            >确定</el-button
            >
          </el-form>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { distributionPOST,distributionPUT } from "@/api/transactions/order/orders_config";
export default {
  data() {
    return {
      form: {
        packingFee: "",
        invalidCycle: null,
        deliveryOptions: 0
      },
      rules: {
        packingFee: [
          { required: true, trigger: "blur", message: "打包费不能为空" },
        ],
      },
    };
  },
  mounted() {
    this.queryDistribution();
  },
  methods: { 
    queryDistribution() {
      distributionPOST().then((res) => {
        console.log(res)
        this.form = res.data.body;
      });
    },
    submit(formName) {
      let form = this.$refs[formName];
      this.submitValid(this, form, this.doSubmit);
    },
    doSubmit() {
      distributionPUT(this.form).then((res) => {
        this.$message.success("保存成功");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>